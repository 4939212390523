<template>
    <section class="pt-lg-8 pt-4">
        <div class="container full-height pt-lg-4 pt-8">
            <login-heading heading="Create New Account" class="mb-3" size="lg">
                <template #prepend>
                    <span class="text-2 font-inter-bold fs--1 mb-4">START FOR FREE</span>
                </template>
                <template #info>
                    <p class="d-inline mr-1">Already have an account ?</p>
                    <span class="text-secondary"><router-link class="text-decoration-none" to="/">Sign In</router-link></span>
                </template>
            </login-heading>
<!--            <s-form class="row">-->
<!--                <div class="col-12 col-lg-4 col-md-6">-->
<!--                    <custom-options-selection-field placeholder="Please choose Registration Type"-->
<!--                                                    :options="options"></custom-options-selection-field>-->
<!--                </div>-->
<!--            </s-form>-->
            <transition name="fade" mode="out-in">
                <s-form class="row" v-if="currentSection === 'get-otp'" key="'get-otp'">
                    <div class="col-12 col-lg-4 col-md-6">
                        <validated-input label="Enter Mobile Number" class="c-input label-bold">
                            <template #prepend>
                                <div class="text-white fl-x pr-2"><span class="mr-1">+91</span><span class="d-inline"><i
                                    class="fa fa-caret-down"></i></span></div>
                            </template>
                        </validated-input>
                        <btn block text="Get OTP" @click="getOtp" class="mt-4 border-r--2 auth-button py-3"></btn>
                    </div>
                </s-form>
                <s-form class="row" v-else key="'verify-number'">
                    <div class="col-12 col-lg-4 col-md-6">
                        <validated-input type="password" label="Enter OTP" class="c-input"></validated-input>
                        <div class="fl-x fl-j-e">
                        <span class="font-inter-extra-light text-white fs--1">Not yet recived ?<router-link
                            class="ml-2 link-underline text-white"
                            to="/">Resend</router-link></span>
                        </div>
                        <btn block text="Verity Number" class="mt-4 border-r--2 auth-button py-3"></btn>
                    </div>
                </s-form>
            </transition>
        </div>
    </section>
</template>

<script>
// import CustomOptionsSelectionField from '@components/registration/CustomOptionsSelectionField';
import LoginHeading from '@components/login/LoginHeading';

export default {
    name: 'RegisterMobileVerification',
    components: { LoginHeading },
    data () {
        return {
            currentSection: 'get-otp'
        };
    },
    methods: {
        getOtp () {
            this.currentSection = 'verify-otp';
        }
    }
};
</script>

<style scoped>

</style>
